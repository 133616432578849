import React from 'react'
import Img from 'gatsby-image'

export default function SubBanner({ title, subtitle, image }) {
    return (
        <div className="grid sm:grid-cols-2 bg-gray-100 py-10">
            <div className="text-blue-900 px-12 flex flex-col items-center justify-center">
                <h1 className="font-extrabold text-4xl">{title}</h1>
                <p className="w-auto h-auto text-sm p-4">{subtitle}</p>
            </div>
            <div className="flex justify-center py-4">
                {image.fluid ? <Img className="sm:w-1/2 w-1/2" fluid={image.fluid} /> : <img className="sm:w-1/2 w-1/2" src={image} alt="Contact logo"></img>}
            </div>
        </div>
    )
}
