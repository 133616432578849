import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SubBanner from '../components/SubBanner'
import SubpageContent from '../components/Subpage/SubpageContent'
import ContactBanner from '../components/ContactBanner'



export default function Planning({ data }) {
  const { title, subtitle: { subtitle }, bannerImage } = data.contentfulSubpagesBanner
  const { subpageContentSection } = data.contentfulSubpageContent


  return (
    <Layout>
      <SEO title={title} description={subtitle} />
      <SubBanner title={title} subtitle={subtitle} image={bannerImage} />
      <SubpageContent content={subpageContentSection} booking="To learn more about planning your build, " />
      <ContactBanner />
    </Layout>
  )
}

export const query = graphql`
query Planning {
    contentfulSubpagesBanner(contentful_id: { eq: "1dZguReFCXvWld5iPH5wOh"}) {
      title
      subtitle {
          subtitle
      }
      bannerImage {
        fluid {
            ...GatsbyContentfulFluid
        }
      }
    }
    contentfulSubpageContent(contentful_id: {eq: "3Hv1zHmdBzEhAiXLGPJINN"}) {
      subpageContentSection {
        title
        subpageImage {
          file {
            url
          }
        }
        childContentfulSubpageContentBlockSubpageContentTextNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`


