import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'


export default function ContactBanner() {
  const data = useStaticQuery(graphql`
   query {
        contentfulGetStartedBanner {
          leadingSentence
          leadingParagraph
          subtitle
          secondaryParagraph
          buttonText
          bannerImage {
            file {
              url
            }
          }
        }
      }
    `)

  const { leadingSentence, leadingParagraph, subtitle, secondaryParagraph, buttonText, bannerImage: { file: { url } } } = data.contentfulGetStartedBanner
  return (
    <section id="contactbanner" className="bg-blue-900 p-10 flex flex-col items-center">
      <div className="text-blue-100 flex flex-col items-center px-10 sm:max-w-5xl sm:text-left text-center">
        <p className="sm:text-4xl text-2xl font-semibold">{leadingSentence}</p>
        <p className="sm:px-6 pt-6">{leadingParagraph}</p>

        <div className="flex pt-6">
          <img className="sm:w-2/4 w-0" src={url} alt="House under construction."></img>
          <div className="pt-10 flex flex-col justify-center items-center p-4">
            <span className="font-semibold">{subtitle}</span>
            <p>{secondaryParagraph}</p>
            <Link to="/contact">
              <button className="btn btn-blue m-4">{buttonText}</button>
            </Link>
          </div>
        </div>
      </div>
    </section >
  )
}
