import React from 'react'
import { Link } from 'gatsby'
// import useSanitize from '../../hooks/useSanitize'


export default function SubpageContent({ content, booking }) {
    // const [contentSanitized, setContentSanitized] = useState(null)

    function displayMarkdown() {
        return content.map((section, index) => {
            const { title, childContentfulSubpageContentBlockSubpageContentTextNode: { childMarkdownRemark: { html } } } = section;
            return (
                <div key={index} className="py-4 my-6">
                    <div className="font-semibold text-2xl text-blue-900 pb-4">{title}</div>
                    <div className="text-blue-800" dangerouslySetInnerHTML={{ __html: html }}></div>
                    <div className="flex justify-end">
                        {section.subpageImage && <img className="max-w-40 w-40" src={section.subpageImage.file.url} alt="About"></img>}
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="subpage-container lg:px-40">
            {displayMarkdown()}
            <div className="font-bold text-blue-900">{booking} <Link className="text-blue-700 hover:text-blue-800" to="/contact">contact us</Link> today.</div>
        </div>
    )
}
